import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { OperatorFunction } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { CurrentPrevious } from '../../../../../../shared-rail-performance/models/current-previous';
import { PagedResultWithPointers } from '../../../../../../shared-rail-performance/models/paging/paged-result';
import { Cycle } from '../../../../../models/cycle/cycle';
import { CycleHistory } from '../../../../../models/cycle/cycle-history';
import { CycleFilters } from '../../../../../models/filters/cycle-filters';
import { CycleTimeByLocationAndMonth } from '../../../../../models/cycle/cycle-time-average-by-location-and-month';
import { DataQueryServiceConfiguration } from '../../../../../../shared-rail-performance/services/data-query/data-query-service-configuration';
import { DataQueryService } from '../../../../../../shared-rail-performance/services/data-query/data-query.service';

@Injectable({
  providedIn: 'root'
})
export class CycleDataService extends DataQueryService {
  getCycleTimeAveragesByLocationAndMonthChart(transform: OperatorFunction<CycleTimeByLocationAndMonth[], DataSource>) {
    return this.getWithTransform(CycleDataServiceConfiguration.CycleTimeByLocationAndMonthChart, transform, 'chart');
  }

  getCycleTimeAverage(cycleFilters: CycleFilters) {
    return this.post(CycleDataServiceConfiguration.CycleTimeAverage, cycleFilters);
  }

  getCycleCount(cycleFilters: CycleFilters) {
    return this.post(CycleDataServiceConfiguration.CycleCount, cycleFilters);
  }

  getCycles(cycleFilters: CycleFilters) {
    return this.post(CycleDataServiceConfiguration.Cycles, cycleFilters);
  }

  getHistoricalCycles(cycleFilters: CycleFilters, transform: OperatorFunction<PagedResultWithPointers<CycleHistory, CycleFilters>, GridDataResult>) {
    return this.postWithTransform(CycleDataServiceConfiguration.CycleHistory, transform, cycleFilters);
  }
}

abstract class CycleDataServiceConfiguration {
  static readonly CycleHistory = new DataQueryServiceConfiguration<DataSource>(
    'cycle-history',
    environment.apiUrl + 'Cycles/Historical'
  );

  static readonly CycleTimeByLocationAndMonthChart = new DataQueryServiceConfiguration<DataSource>(
    'cycle-time-by-location-and-month',
    environment.apiUrl + 'Cycles/Summary'
  );

  static readonly CycleTimeAverage = new DataQueryServiceConfiguration<CurrentPrevious>(
    'cycle-time-average',
     environment.apiUrl + 'Cycles/Average'
  );

  static readonly CycleCount = new DataQueryServiceConfiguration<CurrentPrevious>(
    'cycle-count',
     environment.apiUrl + 'Cycles/Count'
  );

  static readonly Cycles = new DataQueryServiceConfiguration<Cycle[]>(
    'cycles',
     environment.apiUrl + 'Cycles'
  );
}