import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { SubSink } from 'subsink';
import { addMonths, startOfMonth } from 'date-fns';
import { BehaviorSubject, combineLatest, filter, from, map, mergeMap, Observable } from 'rxjs';
import { QueryObserverResult } from '@ngneat/query';
import { AverageTripTimeMapService } from './average-trip-time-map.service';
import MapView from '@arcgis/core/views/MapView';
import _ from 'lodash';
import { sortBy } from 'sort-by-typescript';
import ColorScale from "color-scales";
import { State } from '@progress/kendo-data-query';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Categories } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/categories';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { CurrentPrevious } from '../../../../../../shared-rail-performance/models/current-previous';
import { PagedResultWithPointers } from '../../../../../../shared-rail-performance/models/paging/paged-result';
import { FormattingService } from '../../../../../../shared-rail-performance/services/formatting.service';
import { DashboardType } from '../../../../../constants/dashboard-type';
import { Trip } from '../../../../../models/trip/trip';
import { TripHistory } from '../../../../../models/trip/trip-history';
import { DashboardReportFormService } from '../../../../layout/services/dashboard-report-form.service';
import { TripDataService } from './trip-data.service';
import { groupBy } from '../../../../../../shared-rail-performance/functions/array-functions';
import { Month } from "../../../../../../shared-rail-performance/models/month";
import { TripMetric } from '../../../../../constants/trip-metric';
import { TripFilters } from '../../../../../models/filters/trip-filters';
import { DataExportService } from '../../../../../../shared-rail-performance/services/data-export/data-export.service';
import { ChartService } from '../../../../../../shared-rail-performance/services/charts/chart.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardTripTimeFormService implements OnDestroy {
  private mapView?: MapView;
  isMapExpanded$ = new BehaviorSubject<boolean>(false);

  filterForm: FormGroup;

  isMapLoading$ = new BehaviorSubject<boolean>(false);

  averageTripTimeQueryResult$?: Observable<QueryObserverResult<CurrentPrevious, Error>>;
  tripCountQueryResult$?: Observable<QueryObserverResult<CurrentPrevious, Error>>;
  tripsQueryResult$?: Observable<QueryObserverResult<Trip[], Error>>;
  tripHistoryQueryResult$?: Observable<QueryObserverResult<GridDataResult, Error>>;

  tripHistoryPaging: State = {
    skip: 0,
    take: 10,
  };

  private sub = new SubSink();

  constructor(private formBuilder: FormBuilder,
    private dashboardReportFormService: DashboardReportFormService,
    private tripDataService: TripDataService,
    private tripMapService: AverageTripTimeMapService,
    private formattingService: FormattingService,
    private dataExportService: DataExportService,
    private chartService: ChartService) {
    let months = this.getLast6Months().map(x => x.getMonth() + 1);
    let years = this.getLast6Months().map(x => x.getFullYear()).filter(this.onlyUnique);

    this.filterForm = this.formBuilder.group({
      years: new FormControl<number[]>(years),
      months: new FormControl<number[]>(months),
      tripMetric: new FormControl<string>(TripMetric.TripTime)
    });
  }

  querySummaryData() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<TripFilters>(DashboardType.CycleTime);

    this.averageTripTimeQueryResult$ = this.tripDataService.getTripTimeAverage(filters).result$;
    this.tripCountQueryResult$ = this.tripDataService.getTripCount(filters).result$;
  }

  queryTripHistory() {
    let page = this.tripHistoryPaging.skip! / this.tripHistoryPaging.take!;

    this.queryTripHistoryByPage(page);
  }

  queryTripHistoryByPage(page: number) {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<TripFilters>(DashboardType.CycleTime);
    filters.months = this.months;
    filters.years = this.years;
    filters.pageSize = this.tripHistoryPaging.take;
    filters.page = page;
    filters.tripMetric = this.tripMetric;

    let transform = (tripHistory$: Observable<PagedResultWithPointers<TripHistory, TripFilters>>) => this.aggregateHistoryRecordsByOriginDestination(tripHistory$, this.tripMetric);

    this.tripHistoryQueryResult$ = this.tripDataService.getHistoricalTrips(filters, transform).result$;
  }

  aggregateHistoryRecordsByOriginDestination(tripHistory$: Observable<PagedResultWithPointers<TripHistory, TripFilters>>, tripMetric: string) {
    return tripHistory$.pipe(map((tripHistoryRecords) => {
      let categories: string[] = [];

      let uniqueMonthYears = groupBy<TripHistory>(tripHistoryRecords.results.sort(sortBy('year', 'month')), (record: any) => this.formattingService.formatMonthNameYear(new Date(record.year, record.month - 1, 1)));

      uniqueMonthYears.forEach((uniqueMonthYear: any) => {
        categories.push(uniqueMonthYear.key);
      });

      let uniqueOriginDestinations = groupBy<TripHistory>(tripHistoryRecords.results.sort(sortBy('originCity', 'originStateProvince', 'destinationCity', 'destinationStateProvince', 'year', 'month')), (record) => {
        return record.originCity + '-' + record.originStateProvince + '-' + record.destinationCity + '-' + record.destinationStateProvince;
      });

      let maxTripTimeAverage = _.maxBy(tripHistoryRecords.results, x => x.tripTimeAverage)?.tripTimeAverage ?? 0;
      let maxTripCount = _.maxBy(tripHistoryRecords.results, x => x.tripCount)?.tripCount ?? 0;

      let maxValueToUse = 0;

      if (tripMetric == TripMetric.TripTime) {
        maxValueToUse = maxTripTimeAverage;
      }
      else if (tripMetric == TripMetric.TripCount) {
        maxValueToUse = maxTripCount;
      }

      let tripTimeColorScale = new ColorScale(0, maxValueToUse, ["#00FF00", "#FFFF00", "#FF0000"], 1);

      let gridDataResult: GridDataResult = {
        data: [],
        total: tripHistoryRecords.total
      };

      uniqueOriginDestinations!.sort(sortBy('key')).forEach((group: any) => {
        let dataSource: DataSource = {
          "chart": {
            "divlineColor": "#999999",
            "divLineIsDashed": "1",
            "divLineDashLen": "1",
            "divLineGapLen": "1",
            "toolTipBgAlpha": "0",
            "showValues": "1",
            "placeValuesInside": "0",
            "showYAxisValues": "0",
            "bgAlpha": "0",
            "canvasbgAlpha": "0",
            "chartLeftMargin": "0",
            "chartTopMargin": "0",
            "chartRightMargin": "0",
            "chartBottomMargin": "0",
            "showHoverEffect": "0",
            "plotHoverEffect": "0",
            "showBorder": "0",
            "showPlotBorder": "0",
            "borderThickness": "0",
            "theme": "fusion",
            "showToolTip": "0",
            "adjustDiv": "0",
            "yAxisMinValue": "0",
            "yAxisMaxValue": (maxValueToUse * 2).toString(),
            "numDivLines": "0"
          },
          categories: [Categories.getCategoriesFromStringList(categories)],
          columns: categories,
          data: group.items.sort(sortBy('year', 'month')).map((item: any) => {
            if (tripMetric == TripMetric.TripTime && item.tripTimeAverage && item.tripTimeAverage > 0) {
              return {
                value: item.tripTimeAverage.toString(),
                color: tripTimeColorScale.getColor(item.tripTimeAverage).toHexString()
              };
            }
            else if (tripMetric == TripMetric.TripCount && item.tripCount && item.tripCount > 0) {
              return {
                value: item.tripCount.toString(),
                color: tripTimeColorScale.getColor(item.tripCount).toHexString()
              };
            }
            else {
              return {
                value: 'N/A'
              };
            }
          }),
          rawData: group.items
        };

        gridDataResult.data.push(dataSource);
      });

      return gridDataResult;
    }));
  }

  loadTripMap(mapViewElement: ElementRef, expandButtonClick: () => void) {
    this.isMapLoading$.next(true);
    this.queryTrips();

    this.sub.sink = this.tripsQueryResult$?.pipe(mergeMap((results) => {
      return from(this.tripMapService.loadTrips(mapViewElement, results.data ?? [], expandButtonClick).then((mapView: any) => {
        this.mapView = mapView;
      })).pipe(map(() => {
        return results;
      }))
    }), filter((results) => {
      return !results.isLoading;
    })).subscribe(() => {
      this.isMapLoading$.next(false);
    });
  }

  queryTrips() {
    let filters = this.dashboardReportFormService.getFiltersByDashboard<TripFilters>(DashboardType.CycleTime);
    filters.months = this.months;
    filters.years = this.years;
    
    this.tripsQueryResult$ = this.tripDataService.getTrips(filters).result$;
  }

  onlyUnique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  getLast6Months(): Date[] {
    let months: Date[] = [];
    let currentMonth = startOfMonth(new Date());

    for (let x = 0; x < 6; x++) {
      months.push(currentMonth);
      currentMonth = addMonths(currentMonth, -1);
    }

    return months;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  get yearsFormControl(): FormControl<number[]> {
    return this.filterForm.get('years') as FormControl<number[]>;
  }

  get years(): number[] {
    return this.yearsFormControl.value as number[];
  }

  get monthsFormControl(): FormControl<number[]> {
    return this.filterForm.get('months') as FormControl<number[]>;
  }

  get months(): number[] {
    return this.monthsFormControl.value as number[];
  }

  get tripMetricControl(): FormControl<string> {
    return this.filterForm.get('tripMetric') as FormControl<string>;
  }

  get tripMetric(): string {
    return this.tripMetricControl.value as string;
  }

  updateMonths(months: Month[]) {
    this.monthsFormControl.setValue(months.map(x => x.number));
  }

  updateYears(years: number[]) {
    this.yearsFormControl.setValue(years);
  }
}
