import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { OperatorFunction } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';
import { DataSource } from '../../../../../../shared-rail-performance/models/charts/fusion-charts/data-source';
import { CurrentPrevious } from '../../../../../../shared-rail-performance/models/current-previous';
import { PagedResultWithPointers } from '../../../../../../shared-rail-performance/models/paging/paged-result';
import { Trip } from '../../../../../models/trip/trip';
import { TripHistory } from '../../../../../models/trip/trip-history';
import { TripTimeByLocationAndMonth } from '../../../../../models/trip/trip-time-average-by-location-and-month';
import { TripFilters } from '../../../../../models/filters/trip-filters';
import { DataQueryService } from '../../../../../../shared-rail-performance/services/data-query/data-query.service';
import { DataQueryServiceConfiguration } from '../../../../../../shared-rail-performance/services/data-query/data-query-service-configuration';

@Injectable({
  providedIn: 'root'
})
export class TripDataService extends DataQueryService {
  getTripTimeAveragesByLocationAndMonthChart(transform: OperatorFunction<TripTimeByLocationAndMonth[], DataSource>) {
    return this.getWithTransform(TripDataServiceConfiguration.TripTimeByLocationAndMonthChart, transform, 'chart');
  }

  getTripTimeAverage(tripFilters: TripFilters) {
    return this.post(TripDataServiceConfiguration.TripTimeAverage, tripFilters);
  }

  getTripCount(tripFilters: TripFilters) {
    return this.post(TripDataServiceConfiguration.TripCount, tripFilters);
  }

  getTrips(tripFilters: TripFilters) {
    return this.post(TripDataServiceConfiguration.Trips, tripFilters);
  }

  getHistoricalTrips(tripFilters: TripFilters, transform: OperatorFunction<PagedResultWithPointers<TripHistory, TripFilters>, GridDataResult>) {
    return this.postWithTransform(TripDataServiceConfiguration.TripHistory, transform, tripFilters);
  }
}

abstract class TripDataServiceConfiguration {
  static readonly TripHistory = new DataQueryServiceConfiguration<DataSource>(
    'trip-history',
    environment.apiUrl + 'Trips/Historical'
  );

  static readonly TripTimeByLocationAndMonthChart = new DataQueryServiceConfiguration<DataSource>(
    'trip-time-by-location-and-month',
    environment.apiUrl + 'Trips/Summary'
  );

  static readonly TripTimeAverage = new DataQueryServiceConfiguration<CurrentPrevious>(
    'trip-time-average',
     environment.apiUrl + 'Trips/Average'
  );

  static readonly TripCount = new DataQueryServiceConfiguration<CurrentPrevious>(
    'trip-count',
     environment.apiUrl + 'Trips/Count'
  );

  static readonly Trips = new DataQueryServiceConfiguration<Trip[]>(
    'trips',
     environment.apiUrl + 'Trips'
  );
}